import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import myparcelnl from './myparcelnl';
import myparcelnlLegacy from './myparcelnl-legacy';
import sendmyparcelbe from './sendmyparcelbe';
import sendmyparcelbeLegacy from './sendmyparcelbe-legacy';
import useFeatures from '@/composables/useFeatures';
import {ConfigKey} from '@/providers/platformConfig.types';

type Mapping = Record<ConfigKey, () => MenuItem[]>;

const mappingLegacy: Mapping = {
  [ConfigKey.DEFAULT]: myparcelnlLegacy,
  [ConfigKey.MYPARCEL_NL]: myparcelnlLegacy,
  [ConfigKey.MYPARCEL_BE]: sendmyparcelbeLegacy,
};

const mapping = {
  [ConfigKey.DEFAULT]: myparcelnl,
  [ConfigKey.MYPARCEL_NL]: myparcelnl,
  [ConfigKey.MYPARCEL_BE]: sendmyparcelbe,
};

const menu = (key: keyof typeof mappingLegacy): MenuItem[] => {
  const {isFeatureActive} = useFeatures();

  if (isFeatureActive('uiSideMenu')) {
    return mapping.hasOwnProperty(key) ? mapping[key]() : mapping[ConfigKey.DEFAULT]();
  }

  return mappingLegacy.hasOwnProperty(key) ? mappingLegacy[key]() : mappingLegacy[ConfigKey.DEFAULT]();
};

export default menu;
