import type {Features} from '@/types/features.types';

/**
 * Checks localstorage for the feature flag.
 * The feature string can be namespaced with a dot.
 * The string matches if the feature is enabled or if the feature is namespaced and the namespace is enabled.
 *
 * @depricated - use the function from the utils lib
 */
export const featureEnabledInLocalStorage = (feature: keyof Features): boolean => {
  const hasFull = localStorage.getItem(feature);

  if (hasFull) {
    const full = JSON.parse(hasFull);
    return full;
  }

  const [namespace] = feature.split('.');
  const hasNamespace = localStorage.getItem(namespace);

  if (hasNamespace) {
    const sparse = JSON.parse(hasNamespace);
    return sparse;
  }

  return false;
};
