import type {MenuItemConfig} from '@myparcel-frontend/ui';
import type {RouteRecordRaw} from 'vue-router';
import {ref} from 'vue';
import useAuth from '@/composables/useAuth';
import menu from '@/configs/menu';
import getRoutes from '@/configs/router/routes';
import {useConfigStore} from '@/store/config';

export type InternalMenuItemConfig = MenuItemConfig & {
  show?: boolean | (() => boolean);
  permissions?: string[];
  multiplyLabel?: boolean;
};

const allRoutes = getRoutes();
const menuRef = ref<InternalMenuItemConfig[]>([]);

/**
 * Helper hook to access and manipulate the side menu.
 */
export default function useMenu() {
  const configStore = useConfigStore();

  const showItem = (item: InternalMenuItemConfig) => {
    if (item.hasOwnProperty('show')) {
      if (typeof item.show === 'function') {
        return item.show();
      }

      return item.show;
    }

    return true;
  };

  const itemIsVisible = (item: InternalMenuItemConfig) => {
    if (item.type === 'parent') {
      return item.children.some(itemIsVisible);
    }

    if (item.type === 'internal') {
      return routeHasPermissions(item.to.name) && showItem(item);
    }

    if (item.type === 'external') {
      return showItem(item);
    }

    return showItem(item);
  };

  /**
   * Find a route in the routesArray by its name
   */
  function findByRouteName(route: string): RouteRecordRaw | null {
    function search(item: RouteRecordRaw): RouteRecordRaw | null {
      if (item.name === route) {
        return item;
      }

      if (item.children) {
        for (const child of item.children) {
          const found = search(child);

          if (found) {
            // also adds the parents permissions to the child
            return {
              ...found,
              meta: {permissions: [...(item.meta?.permissions || []), ...(found.meta?.permissions || [])]},
            } as RouteRecordRaw;
          }
        }
      }

      return null;
    }

    for (const element of allRoutes) {
      const foundItem = search(element);

      if (foundItem) {
        return foundItem;
      }
    }

    return null;
  }

  /**
   * Returns false if the route has permissions and the user does not have them
   */
  function routeHasPermissions(routeName: string): boolean {
    const {hasPermissions} = useAuth();
    const route = findByRouteName(routeName);

    if (!route) {
      return false;
    }

    if (route.meta && route.meta.permissions) {
      return hasPermissions(route.meta.permissions);
    }

    return true;
  }

  const getMenu = () => {
    const initialMenu = menu(configStore.config.platform_key);
    const filteredMenu = initialMenu.reduce<InternalMenuItemConfig[]>((acc, item) => {
      const visibleChildren = item.children?.filter(itemIsVisible) ?? [];

      if (itemIsVisible(item)) {
        // If there is exactly one child the parent should be the link instead of a dropdown with one item
        // If there is more than one child the parent should be a dropdown
        // If the parent has no children it should stay a link/divider etc.
        if (visibleChildren.length === 1) {
          acc.push({...visibleChildren[0], icon: item.icon, label: item.label});
        } else if (visibleChildren.length) {
          acc.push({...item, children: visibleChildren});
        } else {
          delete item.children;
          acc.push(item);
        }
      }

      return acc;
    }, []);

    menuRef.value = filteredMenu;
    return menuRef;
  };

  return {menu: menuRef, itemIsVisible, getMenu};
}
