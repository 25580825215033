<template>
  <li
    class="menu-item"
    :class="{
      active: isActive(route, item),
    }">
    <button
      type="button"
      class="w-full"
      :class="{
        active: !item.children && isActive(route, item),
        'whitespace-nowrap': !item.children,
      }"
      @mouseenter="open"
      @mouseleave="close"
      @click="open">
      <MenuItemContent
        :item="item"
        is-sidebar-collapsed>
        <slot />
      </MenuItemContent>
    </button>

    <div
      v-show="isOpen && item.children"
      role="dialog"
      @mouseenter="open"
      @mouseleave="close">
      <ul class="menu-children overflow-hidden">
        <slot name="children" />
      </ul>
    </div>
  </li>
</template>

<script setup lang="ts">
import type {MenuItem} from './menuItem.types';
import {ref} from 'vue';
import {useRoute} from 'vue-router';
import MenuItemContent from '@/components/ui/layout/SideMenuLegacy/menuItemContent/MenuItemContent.vue';
import useMenu from '@/composables/useMenu/useMenuLegacy';

const route = useRoute();
const {isActive} = useMenu();

const props = defineProps<{
  item: MenuItem;
}>();

const isOpen = ref(false);

const open = (): void => {
  if (!props.item.children) {
    return;
  }

  isOpen.value = true;
};

const close = (): void => {
  isOpen.value = false;
};
</script>

<style lang="scss" scoped>
button {
  @apply hover:bg-gray-200;
}

button.active {
  @apply font-bold;
  box-shadow: inset 4px 0 0px -2px var(--color-secondary);
}

.menu-item.parent > button {
  @apply my-1 px-2;
}

.menu-item.open.parent {
  @apply mb-1;
}

.menu-item.open.parent > button {
  @apply mb-0;
}

.menu-item.active.closed.parent > button {
  @apply font-bold;
  box-shadow: inset 4px 0 0px -2px var(--color-secondary);
}
</style>
