<template>
  <nav
    v-if="menu.length"
    id="aria-side-menu-collapsed"
    class="!z-[20] hidden lg:block side-menu"
    :aria-label="$t('navigation.main')">
    <div class="flex flex-col h-full justify-between">
      <ul class="side-menu-list">
        <template v-for="(item, index) in menu">
          <Divider
            v-if="item.key === 'divider'"
            :key="`${item.key}[${index}]`" />
          <template v-else>
            <MenuItemCollapsed
              :key="item.key"
              :item="item"
              class="closed parent relative">
              <template
                v-if="item.children"
                #children>
                <div class="absolute bg-white border left-16 pr-3 py-2 rounded-lg shadow-lg top-1 z-40">
                  <ul>
                    <template v-for="subItem in item.children">
                      <Divider
                        v-if="subItem.key === 'divider'"
                        :key="`${subItem.key}[${index}]`" />
                      <template v-else>
                        <MenuItemCollapsed
                          :key="subItem.key($t)"
                          :item="subItem">
                          {{ subItem.key($t) }}
                        </MenuItemCollapsed>
                      </template>
                    </template>
                  </ul>
                </div>
              </template>
            </MenuItemCollapsed>
          </template>
        </template>
      </ul>
      <div class="border-t flex flex-col pb-0.5">
        <div
          class="cursor-pointer flex hover:bg-gray-200 justify-center my-1 py-2.5 relative"
          :title="$t('navigation.options')"
          role="button"
          data-test="addendum-toggle"
          @click="addendumBubbleToggle.updateToggle(true)"
          @mouseenter="addendumBubbleToggle.updateToggle(true)"
          @mouseleave="addendumBubbleToggle.updateToggle(false)">
          <IconBase
            :size="IconSize.Medium"
            class="opacity-60">
            <More24 />
          </IconBase>
          <div
            data-test="addendum-bubble"
            class="-bottom-10 absolute bg-white border left-16 path py-2 rounded-lg shadow-lg z-40"
            :aria-hidden="!addendumBubbleToggle.toggle.value"
            :class="{hidden: !addendumBubbleToggle.toggle.value}">
            <ul id="navigation--extra--list">
              <li>
                <a
                  href="/settings/account/contact"
                  class="whitespace-nowrap">
                  {{ $t('accountSetting', 2) }}
                </a>
              </li>
              <li>
                <a
                  v-if="hasPermissions(['insertUser', 'deleteUser', 'manageUsers'])"
                  href="/users"
                  class="whitespace-nowrap">
                  {{ $t('user', 2) }}
                </a>
              </li>
              <li>
                <button
                  id="logout-button"
                  type="button"
                  class="text-red-600 whitespace-nowrap"
                  @click="() => logout()">
                  {{ $t('logout') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <hr class="mx-n3 px-n3 w-100" />
        <div
          class="cursor-pointer flex hover:bg-gray-200 justify-center my-1 py-3.5 relative"
          :title="$t('navigation.language')"
          role="button"
          data-test="language-toggle"
          @click="languageBubbleToggle.toggleToggle()"
          @mouseenter="languageBubbleToggle.updateToggle(true)"
          @mouseleave="languageBubbleToggle.updateToggle(false)">
          <FlagIcon :country="$t('meta.flag')" />
          <div
            data-test="language-bubble"
            class="absolute bg-white border bottom-3 left-16 path px-0 py-2 rounded-lg shadow-lg text-sm z-40"
            :class="{hidden: !languageBubbleToggle.toggle.value}">
            <LanguageToggle is-sidebar-collapsed />
          </div>
        </div>
        <hr class="mx-n3 px-n3 w-100" />
        <div class="cursor-pointer flex hover:bg-gray-200 justify-center my-1 py-0 relative">
          <CollapseMenuButton />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import {IconBase, More24, IconSize, FlagIcon} from '@myparcel-frontend/ui';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import CollapseMenuButton from './CollapseMenuButton/CollapseMenuButton.vue';
import MenuItemCollapsed from './menuItem/MenuItemCollapsed.vue';
import LanguageToggle from '@/components/ui/LanguageToggle/LanguageToggle.vue';
import Divider from '@/components/ui/layout/SideMenuLegacy/Divider.vue';
import useAuth from '@/composables/useAuth';
import useMenu from '@/composables/useMenu/useMenuLegacy';
import useToggle from '@/composables/useToggle';

const {logout, hasPermissions} = useAuth();
const {getMenu, itemIsVisible} = useMenu();
const route = useRoute();
const menu = getMenu();
const addendumBubbleToggle = useToggle();
const languageBubbleToggle = useToggle();
</script>

<style lang="scss" scoped>
.side-menu {
  overflow-y: initial;
  width: 57px;
}

#navigation--extra--list li {
  @apply px-5 hover:bg-gray-200 py-1 cursor-pointer;
}
</style>
