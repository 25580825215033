import type {EventSocketMessage} from '@myparcel-frontend/mypa-api-services/event';
import type {SalesChannel} from '@myparcel-frontend/mypa-api-services/order-etl';
import type {ValueOf} from '@myparcel-frontend/types';
import type {ComposerTranslation} from 'vue-i18n';
import {SalesChannelsStatus} from '@myparcel-frontend/mypa-api-services/order-etl';
import useNotifications, {NotificationVariant} from '@/composables/useNotifications';
import {useSalesChannelStore} from '@/store/salesChannelStore/salesChannelStore';

export default function useSalesChannelStatusChangedEvent(message: EventSocketMessage, t: ComposerTranslation): void {
  const {
    updateSalesChannelToDelegationIssued,
    updateSalesChannelToDelegationRequested,
    updateSalesChannelToDelegationRequired,
  } = useSalesChannelStore();

  const sendNotification = (salesChannel: SalesChannel): void => {
    const {addNotification} = useNotifications();

    let variant: ValueOf<typeof NotificationVariant> = NotificationVariant.Success;
    const body = t(`salesChannels.notifications.salesChannelStatusChanged.${salesChannel.status}`, {
      name: salesChannel.name,
    });

    if (salesChannel.status === SalesChannelsStatus.DelegationRequested) {
      variant = NotificationVariant.Warning;
    }

    if (salesChannel.status === SalesChannelsStatus.DelegationRequired) {
      variant = NotificationVariant.Error;
    }

    addNotification({
      variant,
      body,
    });
  };

  const handle = (message: EventSocketMessage): void => {
    let updatedSalesChannel: SalesChannel | undefined;

    const {salesChannelId, status} = message.event.value;

    switch (status.case) {
      case SalesChannelsStatus.DelegationIssued:
        updatedSalesChannel = updateSalesChannelToDelegationIssued(salesChannelId, status.value);
        break;
      case SalesChannelsStatus.DelegationRequested:
        updatedSalesChannel = updateSalesChannelToDelegationRequested(salesChannelId, status.value);
        break;
      case SalesChannelsStatus.DelegationRequired:
        updatedSalesChannel = updateSalesChannelToDelegationRequired(salesChannelId, status.value);
        break;
      default:
    }

    if (!updatedSalesChannel) {
      return;
    }

    sendNotification(updatedSalesChannel);
  };

  handle(message);
}
