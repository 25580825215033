import type {
  SalesChannelStatusDelegationIssued,
  SalesChannelStatusDelegationRequested,
  SalesChannelStatusDelegationRequired,
} from '@myparcel-frontend/mypa-api-services/event';
import type {
  SalesChannel,
  SalesChannelRequiringDelegation,
  SalesChannelWithIssuedDelegation,
  SalesChannelWithRequestedDelegation,
} from '@myparcel-frontend/mypa-api-services/order-etl';
import {defineStore} from 'pinia';
import {ref} from 'vue';
import {SalesChannelsStatus} from '@/types/salesChannels';

export const useSalesChannelStore = defineStore('salesChannels', () => {
  const salesChannelList = ref<SalesChannel[]>([]);

  const addSalesChannel = (salesChannel: SalesChannel): SalesChannel => {
    salesChannelList.value.push(salesChannel);
    return salesChannel;
  };

  const getSalesChannelById = (id: SalesChannel['id']): SalesChannel | undefined => {
    return salesChannelList.value.find((channel) => channel.id === id);
  };

  const updateSalesChannel = (salesChannel: SalesChannel): SalesChannel | undefined => {
    salesChannelList.value = salesChannelList.value.map((channel) =>
      channel.id === salesChannel.id ? salesChannel : channel,
    );

    return getSalesChannelById(salesChannel.id);
  };

  const removeSalesChannel = (id: SalesChannel['id']): boolean => {
    const existingChannel = getSalesChannelById(id);

    if (!existingChannel) {
      return false;
    }

    salesChannelList.value = salesChannelList.value.filter((salesChannel) => salesChannel.id !== id);

    return true;
  };

  const updateSalesChannelToDelegationIssued = (
    salesChannelId: SalesChannel['id'],
    newSalesChannelValues: SalesChannelStatusDelegationIssued['value'],
  ): SalesChannel | undefined => {
    let updatedSalesChannel: SalesChannelWithIssuedDelegation;
    const salesChannel = getSalesChannelById(salesChannelId);

    if (!salesChannel) {
      return;
    }

    switch (salesChannel.status) {
      case SalesChannelsStatus.DelegationRequested:
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
        const {authorizeUrl, ...restOfSalesChannelWithRequestedDelegation} = salesChannel;

        updatedSalesChannel = {
          ...restOfSalesChannelWithRequestedDelegation,
          status: SalesChannelsStatus.DelegationIssued,
          extraction: {
            ...salesChannel.extraction,
            ...newSalesChannelValues.extraction,
          },
        };
        break;
      case SalesChannelsStatus.DelegationRequired:
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
        const {delegationError, ...restOfSalesChannelWithRequiredDelegation} = salesChannel;

        updatedSalesChannel = {
          ...restOfSalesChannelWithRequiredDelegation,
          status: SalesChannelsStatus.DelegationIssued,
          extraction: {
            ...salesChannel.extraction,
            ...newSalesChannelValues.extraction,
          },
        };
        break;
      default:
        updatedSalesChannel = {
          ...salesChannel,
          extraction: {
            ...salesChannel.extraction,
            ...newSalesChannelValues.extraction,
          },
        };
        break;
    }

    return updateSalesChannel(updatedSalesChannel);
  };

  const updateSalesChannelToDelegationRequested = (
    salesChannelId: SalesChannel['id'],
    newSalesChannelValues: SalesChannelStatusDelegationRequested['value'],
  ): SalesChannel | undefined => {
    const salesChannel = getSalesChannelById(salesChannelId);
    let updatedSalesChannel: SalesChannelWithRequestedDelegation;

    if (!salesChannel) {
      return;
    }

    switch (salesChannel.status) {
      case SalesChannelsStatus.DelegationRequired:
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
        const {delegationError, ...restOfSalesChannelWithRequiredDelegation} = salesChannel;

        updatedSalesChannel = {
          ...restOfSalesChannelWithRequiredDelegation,
          status: SalesChannelsStatus.DelegationRequested,
          authorizeUrl: newSalesChannelValues.authorizeUrl,
        };
        break;
      default:
        updatedSalesChannel = {
          ...salesChannel,
          status: SalesChannelsStatus.DelegationRequested,
          authorizeUrl: newSalesChannelValues.authorizeUrl,
        };
        break;
    }

    return updateSalesChannel(updatedSalesChannel);
  };

  const updateSalesChannelToDelegationRequired = (
    salesChannelId: SalesChannel['id'],
    newSalesChannelValues: SalesChannelStatusDelegationRequired['value'],
  ): SalesChannel | undefined => {
    let updatedSalesChannel: SalesChannelRequiringDelegation;
    const salesChannel = getSalesChannelById(salesChannelId);

    if (!salesChannel) {
      return;
    }

    switch (salesChannel.status) {
      case SalesChannelsStatus.DelegationRequested:
        // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
        const {authorizeUrl, ...restOfSalesChannelWithRequestedDelegation} = salesChannel;

        updatedSalesChannel = {
          ...restOfSalesChannelWithRequestedDelegation,
          status: SalesChannelsStatus.DelegationRequired,
          delegationError: newSalesChannelValues.delegationError,
        };
        break;
      default:
        updatedSalesChannel = {
          ...salesChannel,
          status: SalesChannelsStatus.DelegationRequired,
          delegationError: newSalesChannelValues.delegationError,
        };
        break;
    }

    return updateSalesChannel(updatedSalesChannel);
  };

  return {
    salesChannelList,
    addSalesChannel,
    updateSalesChannel,
    getSalesChannelById,
    removeSalesChannel,
    updateSalesChannelToDelegationIssued,
    updateSalesChannelToDelegationRequested,
    updateSalesChannelToDelegationRequired,
  };
});
