import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import {
  Cog24,
  Dashboard,
  EuroSign24,
  Packaging24,
  Shipment,
  Shirt24,
  ShoppingCart,
  Subscription,
  Support24,
} from '@myparcel-frontend/ui';
import {markRaw} from 'vue';
import useAccount from '@/composables/useAccounts';
import useAuth from '@/composables/useAuth';
import useFeatures from '@/composables/useFeatures';
import {useConfigStore} from '@/store/config';

export default (): MenuItem[] => {
  const {accountSettings, currentShop} = useAccount();
  const {hasPermission} = useAuth();
  const {isFeatureActive} = useFeatures();
  const {config} = useConfigStore();

  const currentShopId = currentShop.value.id;

  return [
    {
      type: 'parent',
      label: 'dashboard',
      icon: markRaw(Dashboard),
      children: [
        {
          type: 'internal',
          to: {
            name: 'dashboard',
          },
          label: 'overview',
        },
        {
          type: 'internal',
          activeRouteNameAliases: ['analytics-dashboard'],
          to: {
            name: 'insights',
          },
          label: 'analytics.title',
        },
      ],
    },
    {
      show: () => Boolean(accountSettings.value.order_mode),
      type: 'parent',
      label: 'menu.items.order',
      multiplyLabel: true,
      icon: markRaw(ShoppingCart),
      children: [
        {
          type: 'internal',
          label: 'overview',
          to: {
            name: 'orders-overview',
          },
        },
        {
          type: 'internal',
          label: 'manualImport',
          to: {
            name: 'orders-import-overview',
          },
        },
      ],
    },
    {
      type: 'parent',
      label: 'shipment',
      multiplyLabel: true,
      icon: markRaw(Shipment),
      children: [
        {
          type: 'internal',
          to: {
            name: 'shipments',
          },
          label: 'overview',
        },
        {
          type: 'internal',
          to: {
            name: 'shipments-create',
          },
          label: 'newShipment',
        },
        {
          type: 'internal',
          to: {
            name: 'shipments-import',
          },
          label: 'multipleShipments',
        },
        {
          type: 'internal',
          to: {
            name: 'addresses',
          },
          label: 'addressBook.title',
        },
        {
          type: 'internal',
          to: {
            name: 'haalservice',
          },
          label: 'collectTrip',
        },
        {
          type: 'internal',
          to: {
            name: 'location-finder',
          },
          label: 'locationFinder.title',
        },
      ],
    },
    {
      type: 'internal',
      label: 'product',
      multiplyLabel: true,
      icon: markRaw(Shirt24),
      to: {
        name: 'products',
      },
      show: () => isFeatureActive('page.products.overview'),
    },
    {
      type: 'parent',
      label: 'packagings.title',
      multiplyLabel: true,
      icon: markRaw(Packaging24),
      children: [
        {
          type: 'internal',
          label: 'packagings.order',
          to: {
            name: 'packagings',
          },
        },
        {
          type: 'external',
          label: 'packagings.orderNewPackaging',
          href: config.webshopUrl,
          show: () => hasPermission('accountSelectOrder'),
        },
      ],
    },
    {
      type: 'parent',
      label: 'financial',
      icon: markRaw(EuroSign24),
      children: [
        {
          type: 'internal',
          to: {
            name: 'invoices',
          },
          label: 'invoice.title',
        },
        {
          type: 'internal',
          to: {
            name: 'invoice-upcoming',
          },
          label: 'invoice.upcomingInvoice',
        },
        {
          type: 'internal',
          to: {
            name: 'credit-debit',
          },
          label: 'exceptionalPayments.title',
        },
        {
          type: 'internal',
          to: {
            name: 'prices',
          },
          label: 'tariff',
        },
      ],
    },
    {
      type: 'parent',
      label: 'support.title',
      icon: markRaw(Support24),
      children: [
        {
          type: 'internal',
          to: {
            name: 'contact',
          },
          label: 'contact.title',
        },
        {
          type: 'internal',
          to: {
            name: 'cases-index',
          },
          label: 'contact.caseSummary',
        },
        {
          type: 'external',
          label: 'faq',
          href: 'https://faq.myparcel.nl/',
        },
        {
          type: 'internal',
          label: 'tellAFriend.tellAFriend',
          to: {
            name: 'tellafriend',
          },
        },
        {
          type: 'external',
          label: 'academy',
          href: 'https://www.myparcel.nl/academy',
        },
      ],
    },
    {
      type: 'divider',
      label: 'account-divider',
    },
    {
      type: 'internal',
      to: {
        name: 'subscription-overview',
      },
      label: 'subscriptions.title',
      multiplyLabel: true,
      icon: markRaw(Subscription),
    },
    {
      type: 'parent',
      label: 'shopSettings.title',
      icon: markRaw(Cog24),
      children: [
        {
          type: 'internal',
          label: 'general',
          to: {
            name: 'shop-settings-general',
            params: {
              shopId: currentShopId,
            },
          },
          show: () => !isFeatureActive('page.settings.shop.shipment.labels'),
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings.branding',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'shopSettings.branding.title',
          show: () => isFeatureActive('page.settings.shop.branding'),
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings-orders',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'shopSettings.orders',
          show: () => isFeatureActive('page.settings.shop.orders') && accountSettings.value.fulfilment,
        },
        ...(isFeatureActive('page.settings.shop.shipment.labels')
          ? [
              {
                type: 'internal',
                to: {
                  name: 'shop-settings-labels',
                  params: {
                    shopId: currentShopId,
                  },
                },
                label: 'shipment',
                multiplyLabel: true,
                activeRouteNameAliases: [
                  'shop-settings-shipping-rules',
                  'shop-settings-shipping-rules-create',
                  'shop-settings-shipping-rules-edit',
                ],
              },
            ]
          : [
              {
                type: 'internal',
                to: {
                  name: 'shop-settings-shipping-rules',
                  params: {
                    shopId: currentShopId,
                  },
                },
                label: 'shipment',
                multiplyLabel: true,
                activeRouteNameAliases: [
                  'shop-settings-shipping-rules',
                  'shop-settings-shipping-rules-create',
                  'shop-settings-shipping-rules-edit',
                ],
              },
            ]),
        {
          type: 'internal',
          to: {
            name: 'shop-settings-track-trace',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'trackTrace',
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings-returns-index',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'return',
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings-portal',
            params: {
              shopId: currentShopId,
            },
          },
          show: () => isFeatureActive('page.settings.shop.portal'),
          label: 'shopSettings.trackAndTracePage.title',
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings-company-billing',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'companySettings',
        },
        {
          type: 'internal',
          to: {
            name: 'shop-settings-integration',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'integration',
        },
        {
          type: 'internal',
          to: {
            name: 'sales-channels',
            params: {
              shopId: currentShopId,
            },
          },
          label: 'salesChannels.menu',
          show: () => isFeatureActive('page.salesChannels'),
        },
      ],
    },
  ];
};
