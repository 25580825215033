import {ButtonSizes, ButtonVariant} from './Button.types';

/** @deprecated */
// eslint-disable-next-line complexity
export const useVariantClasses = (variant: string): string[] => {
  let variantBaseClass = '';
  let variantHoverClass = '';

  switch (variant) {
    case ButtonVariant.Primary:
      variantBaseClass = 'border border-solid bg-secondary border-secondary text-white';
      variantHoverClass = 'hover:bg-secondary-200 hover:border-secondary-200';
      break;
    case ButtonVariant.PrimaryOutline:
      variantBaseClass = 'border border-neutral-200 border-solid bg-white text-primary';
      variantHoverClass = 'hover:bg-neutral-100';
      break;
    case ButtonVariant.Secondary:
      variantBaseClass = 'border border-solid bg-white border-secondary text-secondary';
      variantHoverClass = 'hover:bg-secondary hover:text-white';
      break;
    case ButtonVariant.Modal:
      variantBaseClass = 'border border-solid bg-secondary border-secondary text-white !rounded-full !py-1.5 !px-2.5';
      variantHoverClass = 'hover:bg-secondary-200 hover:border-secondary-200';
      break;
    case ButtonVariant.Basic:
      variantBaseClass = 'text-secondary border border-solid border-transparent';
      variantHoverClass = 'hover:text-link-hover';
      break;
    case ButtonVariant.Danger:
      variantBaseClass = 'border border-solid bg-danger border-danger text-white';
      variantHoverClass = 'hover:bg-danger-200 hover:border-danger-200';
      break;
    case ButtonVariant.DangerOutline:
      variantBaseClass = 'border border-solid border-danger text-danger';
      variantHoverClass = 'hover:bg-danger-200 hover:border-danger-200 hover:text-white';
      break;
    case ButtonVariant.DangerBasic:
      variantBaseClass = 'text-danger border border-solid border-transparent';
      variantHoverClass = 'hover:text-danger-200';
      break;
    case ButtonVariant.SecondaryLight:
      variantBaseClass = 'border border-solid border-secondary bg-secondary-0 text-secondary';
      variantHoverClass = 'hover:bg-secondary hover:text-white';
      break;
    case ButtonVariant.TextPrimary:
      variantBaseClass = 'text-primary !py-0 !px-0 leading-none';
      variantHoverClass = 'hover:underline hover:text-primary-200';
      break;
    case ButtonVariant.None:
    default:
      break;
  }

  return [variantBaseClass, variantHoverClass];
};

/** @deprecated */
export const useButtonSizes = (size: string): string => {
  let sizeClass = '';

  switch (size) {
    case ButtonSizes.MEDIUM:
      sizeClass = 'text-md leading-6 py-[6px] px-[12px]';
      break;
    case ButtonSizes.BADGE:
      sizeClass = 'text-xs leading-0 px-[6px] py-[1px]';
      break;
    case ButtonSizes.NONE:
    default:
      break;
  }

  return sizeClass;
};
