<template>
  <SideMenuCollapsed
    class="absolute left-0 lg:flex lg:static top-0 z-40"
    :class="{
      '!hidden lg:!flex': !mobileMenuOpen,
      flex: mobileMenuOpen,
    }"
    :menu="menu"
    @close="() => closeSidebar()">
    <template #parent-menu-item="{item}">
      <ParentMenuItemCollapsed
        :item="item"
        :active-route-name="route.name">
        <template #external-menu-item="{child}">
          <ExternalMenuItem
            hide-icon
            :item="child"
            child>
            {{ getLabel(child) }}
          </ExternalMenuItem>
        </template>

        <template #internal-menu-item="{child}">
          <InternalMenuItem
            hide-icon
            :item="child"
            :is-active="child.to.name === route.name || child.activeRouteNameAliases?.includes(route.name)"
            :link-component="RouterLink">
            {{ getLabel(child) }}
          </InternalMenuItem>
        </template>
      </ParentMenuItemCollapsed>
    </template>

    <template #external-menu-item="{item}">
      <ExternalMenuItem
        hide-label
        :item="item">
        {{ getLabel(item) }}
      </ExternalMenuItem>
    </template>

    <template #internal-menu-item="{item}">
      <InternalMenuItem
        hide-label
        :is-active="item.to.name === route.name"
        :item="item"
        :link-component="RouterLink">
        {{ getLabel(item) }}
      </InternalMenuItem>
    </template>

    <template #divider-menu-item>
      <DividerMenuItem />
    </template>

    <ul>
      <DividerMenuItem />
      <ParentMenuItemCollapsed
        :active-route-name="route.name"
        icon-class="!text-black-light"
        :item="moreMenuItem">
        <template #internal-menu-item="{child}">
          <InternalMenuItem
            hide-icon
            :item="child"
            :is-active="child.to.name === route.name"
            :link-component="RouterLink">
            {{ getLabel(child) }}
          </InternalMenuItem>
        </template>

        <li>
          <button
            id="logout-button"
            type="button"
            class="hover:bg-gray-200 px-4 py-2 text-left text-red-600 w-full whitespace-nowrap"
            @click="() => logout()">
            {{ $t('logout') }}
          </button>
        </li>
      </ParentMenuItemCollapsed>
      <DividerMenuItem />
      <ParentMenuItemCollapsed
        hide-icon
        :active-route-name="route.name"
        :item="{type: 'parent', children: [], label: 'language-toggle'}">
        <template #label>
          <FlagIcon :country="$t('meta.flag')" />
        </template>
        <LanguageToggle is-sidebar-collapsed />
      </ParentMenuItemCollapsed>
      <DividerMenuItem />
      <li>
        <CollapseMenuButton
          :aria-label="$t('navigation.expand')"
          :is-collapsed="isSidebarCollapsed"
          @click="() => expandSidebar()" />
      </li>
    </ul>
  </SideMenuCollapsed>
</template>

<script setup lang="ts">
import type {InternalMenuItemConfig} from '@/composables/useMenu/useMenu';
import {
  DividerMenuItem,
  ExternalMenuItem,
  InternalMenuItem,
  ParentMenuItemCollapsed,
  SideMenuCollapsed,
  CollapseMenuButton,
  More24,
  FlagIcon,
} from '@myparcel-frontend/ui';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {RouterLink, useRoute} from 'vue-router';
import LanguageToggle from '@/components/ui/LanguageToggle/LanguageToggle.vue';
import useAuth from '@/composables/useAuth';
import useMenu from '@/composables/useMenu';
import useMobileMenu from '@/composables/useMobileMenu';
import useSidebarToggle from '@/composables/useSidebarToggle';

const {mobileMenuOpen, closeSidebar} = useMobileMenu();
const {isSidebarCollapsed, expandSidebar} = useSidebarToggle();
const {getMenu} = useMenu();
const {logout, hasPermissions} = useAuth();
const {t} = useI18n();

const route = useRoute();
const menu = getMenu();

const getLabel = (item: InternalMenuItemConfig) => {
  return t(item.label, item?.multiplyLabel ? 2 : 1);
};

const moreMenuItem = computed(() => {
  return {
    type: 'parent',
    icon: More24,
    label: 'more',
    children: [
      {
        to: {name: 'account-settings-contact'},
        label: 'accountSetting',
        type: 'internal',
      },
      ...(hasPermissions(['insertUser', 'deleteUser', 'manageUsers'])
        ? [
            {
              type: 'internal',
              label: 'user',
              multiplyLabel: true,
              to: {
                name: 'users',
              },
            },
          ]
        : []),
    ],
  };
});
</script>
