/** @depricated - use the function from the utils lib */
export const flattenObject = <T>(obj: T): Record<string, boolean> => {
  function flat(res: Record<string, boolean>, key: string, val: boolean, pre = ''): Record<string, boolean> {
    const prefix = [pre, key].filter((v) => v).join('.');

    return typeof val === 'object' && !Array.isArray(val)
      ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
      : Object.assign(res, {[prefix]: val});
  }

  return Object.keys(obj).reduce((prev, curr) => flat(prev, curr, obj[curr]), {});
};
