import type {Features} from '@/types/features.types';

export const sendmyparcelBeFeatures: Features = {
  uiSideMenu: false,
  addressBook: {
    createReturnShipment: false,
    newForm: false,
  },
  events: {
    sknj: {
      endOfYear: false,
    },
  },
  errors: false,
  contactForm: {
    productsAndPrices: false,
  },
  contactServices: {
    faq: true,
    chat: false,
    whatsapp: false,
    phone: true,
  },
  portal: {
    events: false,
  },
  page: {
    addresses: {
      import: false,
    },
    collectTrip: false,
    dashboard: false,
    locationFinder: false,
    orders: {
      import: false,
      overview: false,
    },
    prices: {
      scale: {
        dhl: false,
        dpd: false,
        index: true,
        postnl: false,
      },
    },
    products: {
      edit: false,
      overview: false,
      new: false,
    },
    salesChannels: false,
    settings: {
      account: {
        contact: false,
        index: false,
        ordersShipping: true,
        shops: false,
        usersSecurity: {
          index: false,
          create: false,
          edit: false,
        },
        myContactInfo: false,
        myLoginInfo: false,
      },
      shop: {
        branding: false,
        company: {
          index: false,
          billing: false,
          return: false,
          export: false,
          webshop: false,
          bank: false,
        },
        general: false,
        orders: false,
        portal: false,
        returns: {
          index: false,
          vfb2: false,
          portal: false,
        },
        shipment: {
          dimensionsPresets: false,
          index: true,
          labels: false,
          shippingRules: {
            vfb2: false,
          },
        },
        trackTrace: false,
      },
    },
    shipments: false,
    users: false,
    webshop: {
      orders: {
        detail: false,
        overview: false,
      },
    },
  },
  services: {
    appcues: true,
  },
  settings: {
    accountSettingsLegacy: true,
    shop: {
      integrations: {
        exact_de: {
          active: false,
          autoImport: false,
        },
        exact: {
          active: false,
          autoImport: false,
        },
        amazon: {
          autoImport: false,
        },
        bol_dot_com: {
          autoImport: true,
        },
        etsy: {
          autoImport: true,
        },
        wix: {
          autoImport: true,
        },
      },
    },
  },
  microservices: {
    event: false,
  },
  subscriptions: {
    myAnalytics: {
      showTransitTimeUsp: false,
      tierFour: false,
    },
  },
  trackAndTraceModal: false,
} as const;
