import {useLocalStorage} from '@vueuse/core';

export const SIDE_MENU_COLLAPSED = 'sideMenuCollapsed';

const isSidebarCollapsed = useLocalStorage(SIDE_MENU_COLLAPSED, false);

export default function UseSidebarToggle() {
  const collapseSidebar = () => {
    isSidebarCollapsed.value = true;
  };

  const expandSidebar = () => {
    isSidebarCollapsed.value = false;
  };

  return {
    isSidebarCollapsed,
    collapseSidebar,
    expandSidebar,
  };
}
