import type {MenuItem} from '@/components/ui/layout/sideMenu/menuItem/menuItem.types';
import {
  Cog24,
  Dashboard,
  EuroSign24,
  Shipment,
  Shirt24,
  ShoppingCart,
  Subscription,
  Support,
} from '@myparcel-frontend/ui';
import {useI18n} from 'vue-i18n';
import useAccount from '@/composables/useAccounts';
import useFeatures from '@/composables/useFeatures';
import {LanguageKey} from '@/types/i18n.types';

export default (): MenuItem[] => {
  const {accountSettings, currentShop} = useAccount();
  const {isFeatureActive} = useFeatures();

  const currentShopId = currentShop.value.id;

  return [
    {
      key: (t) => t('dashboard'),
      icon: {
        icon: Dashboard,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'dashboard',
          key: (t) => t('overview'),
        },
        {
          route: 'insights',
          key: (t) => t('analytics.title'),
        },
      ],
    },
    {
      key: (t) => t('menu.items.order', 2),
      show: () => Boolean(accountSettings.value.order_mode),
      icon: {
        icon: ShoppingCart,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          key: (t) => t('overview'),
          route: 'orders-overview',
        },
        {
          key: (t) => t('manualImport'),
          route: 'orders-import-overview',
        },
      ],
    },
    {
      key: (t) => t('shipment', 2),
      icon: {
        icon: Shipment,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'shipments',
          key: (t) => t('overview'),
        },
        {
          route: 'shipments-create',
          key: (t) => t('newShipment'),
        },
        {
          route: 'shipments-import',
          key: (t) => t('multipleShipments'),
        },
        {
          route: 'addresses',
          key: (t) => t('addressBook.title'),
        },
        {
          key: (t) => t('collectTrip'),
          get redirectRoute() {
            const {locale} = useI18n();

            if (locale.value === LanguageKey.Fr) {
              return 'https://sendmyparcel.be/fr/demander-une-collecte/';
            }

            return 'https://sendmyparcel.be/vraag-een-ophaling-aan/';
          },
          redirect: true,
          target: '_blank',
        },
      ],
    },
    {
      key: (t) => t('product', 2),
      icon: {
        icon: Shirt24,
        viewbox: '0 0 24 24',
      },
      route: 'products',
      show: () => isFeatureActive('page.products.overview'),
    },
    {
      key: (t) => t('financial'),
      icon: {
        icon: EuroSign24,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'invoices',
          key: (t) => t('invoice.title', 2),
        },
        {
          route: 'invoice-upcoming',
          key: (t) => t('invoice.upcomingInvoice'),
        },
        {
          route: 'credit-debit',
          key: (t) => t('exceptionalPayments.title'),
        },
        {
          route: 'prices',
          key: (t) => t('tariff', 2),
        },
      ],
    },
    {
      key: (t) => t('support.title'),
      icon: {
        icon: Support,
        viewbox: '0 0 32 32',
      },
      children: [
        {
          route: 'contact',
          key: (t) => t('contact.title'),
        },
        {
          route: 'cases-index',
          key: (t) => t('contact.caseSummary'),
        },
        {
          route: 'faq',
          key: (t) => t('faq'),
        },
        {
          key: (t) => t('tellAFriend.tellAFriend'),
          route: 'tellafriend',
        },
      ],
    },
    {
      key: 'divider',
    },
    {
      route: 'subscription-overview',
      key: (t) => t('subscriptions.title', 2),
      icon: {
        icon: Subscription,
        viewbox: '0 0 24 24',
      },
    },
    {
      key: (t) => t('shopSettings.title', 2),
      icon: {
        icon: Cog24,
        viewbox: '0 0 24 24',
      },
      children: [
        {
          route: 'shop-settings-general',
          key: (t) => t('general'),
          params: {
            shopId: currentShopId,
          },
          show: () => !isFeatureActive('page.settings.shop.shipment.labels'),
        },
        ...(isFeatureActive('page.settings.shop.shipment.labels')
          ? [
              {
                type: 'internal',
                to: {
                  name: 'shop-settings-labels',
                  params: {
                    shopId: currentShopId,
                  },
                },
                label: 'shipment',
                multiplyLabel: true,
                activeRouteNameAliases: [
                  'shop-settings-shipping-rules',
                  'shop-settings-shipping-rules-create',
                  'shop-settings-shipping-rules-edit',
                ],
              },
            ]
          : [
              {
                type: 'internal',
                to: {
                  name: 'shop-settings-shipping-rules',
                  params: {
                    shopId: currentShopId,
                  },
                },
                label: 'shipment',
                multiplyLabel: true,
                activeRouteNameAliases: [
                  'shop-settings-shipping-rules',
                  'shop-settings-shipping-rules-create',
                  'shop-settings-shipping-rules-edit',
                ],
              },
            ]),
        {
          route: 'shop-settings-track-trace',
          key: (t) => t('trackTrace'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-returns-index',
          key: (t) => t('return', 2),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-portal',
          key: (t) => t('shopSettings.trackAndTracePage.title'),
          params: {
            shopId: currentShopId,
          },
          show: () => isFeatureActive('page.settings.shop.portal'),
        },
        {
          route: 'shop-settings-company',
          key: (t) => t('companySettings'),
          params: {
            shopId: currentShopId,
          },
        },
        {
          route: 'shop-settings-integration',
          key: (t) => t('integration'),
          params: {
            shopId: currentShopId,
          },
        },
      ],
    },
  ];
};
