<template>
  <slot />
</template>

<script setup lang="ts">
import type {EventSocketMessage} from '@myparcel-frontend/mypa-api-services/event';
import {useEventService, EventCaseName} from '@myparcel-frontend/mypa-api-services/event';
import {ref} from 'vue';
import {useI18n} from 'vue-i18n';
import useSalesChannelStatusChangedEvent from '@/composables/events/useSalesChannelStatusChangedEvent';
import useAuth from '@/composables/useAuth';
import useFeatures from '@/composables/useFeatures';
import environment from '@/utils/env';
import log from '@/utils/logging';

const eventService = useEventService();
const {isFeatureActive} = useFeatures();
const {t} = useI18n();

const socket = ref();

/* c8 ignore start */
const getSocketFromEventService = (token: string) => {
  let client;

  if (environment.useMockingApiClients) {
    client = eventService.setMockingClient();
  } else {
    client = eventService.setClient(token);
  }

  client.setToken(token);
  const connectedSocket = client.connect();

  return connectedSocket;
};

const handleMessage = (message: EventSocketMessage): void => {
  switch (message.event.case) {
    case EventCaseName.SalesChannelStatusChanged:
      useSalesChannelStatusChangedEvent(message, t);
      break;
    default:
      break;
  }
};

const initEventSocketListeners = () => {
  if (!socket.value) {
    return;
  }

  socket.value.onmessage = (event: MessageEvent) => {
    try {
      handleMessage(JSON.parse(event.data));
    } catch (error) {
      log(new Error('Error parsing the event data', event));
    }
  };

  socket.value.onerror = (event: ErrorEvent) => {
    log(event.error);
    socket.value?.close();
  };

  socket.value.onclose = () => {
    socket.value = undefined;
  };
};

const {getToken} = useAuth();
const token = await getToken();

const handleSockets = () => {
  /* c8 ignore next 3 - TODO: MST-526 - implement the Event API tests. */
  if (socket.value) {
    socket.value?.close();
  }

  socket.value = getSocketFromEventService(token);
  initEventSocketListeners();
};

if (isFeatureActive('microservices.event')) {
  handleSockets();
}
/* c8 ignore stop */
</script>
