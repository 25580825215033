<template>
  <Toast
    v-if="visible"
    id="standalone"
    class="relative"
    :variant="variant"
    :can-close="canClose"
    :with-shadow="withShadow"
    :timeout="null"
    @remove="removeToast">
    <template #title>
      <slot name="title" />
    </template>
    <slot />
  </Toast>
</template>

<script lang="ts">
/**
 * @deprecated - Use the component from the ui library.
 */
</script>

<script setup lang="ts">
import type {NotificationVariant} from '@/composables/useNotifications';
import {ref} from 'vue';
import Toast from './Toast.vue';

defineProps<{
  variant: NotificationVariant;
  canClose?: boolean;
  withShadow?: boolean;
}>();

const emit = defineEmits(['remove']);

const visible = ref(true);

const removeToast = (): void => {
  visible.value = false;
  emit('remove');
};
</script>
