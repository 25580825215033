<template>
  <li
    class="menu-item"
    :class="{
      active: isActive,
      open: isOpen,
      closed: !isOpen,
    }">
    <button
      type="button"
      class="w-full"
      :class="{
        active: isActive,
        'whitespace-nowrap': !item.children,
      }"
      @click="toggleOpen">
      <MenuItemContent :item="item">
        <slot />
      </MenuItemContent>
    </button>

    <div :data-test="`${item.key($t)}[children]`">
      <SlideOut>
        <ul
          v-show="!!item.children && isOpen"
          class="menu-children overflow-hidden">
          <slot name="children" />
        </ul>
      </SlideOut>
    </div>
  </li>
</template>

<script setup lang="ts">
import type {MenuItem} from './menuItem.types';
import {ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import MenuItemContent from '@/components/ui/layout/SideMenuLegacy/menuItemContent/MenuItemContent.vue';
import SlideOut from '@/components/ui/transitions/SlideOut.vue';
import useMenu from '@/composables/useMenu/useMenuLegacy';

const route = useRoute();
const {isActive: routeIsActive} = useMenu();

const props = defineProps<{item: MenuItem}>();

const isOpen = ref(false);
const isActive = ref(false);

// Watch the routes to give the correct open and active states.
// Just like on backoffice meteor we don't close the a parent menu item when selecting another one
watch(route, () => {
  if (!routeIsActive(route, props.item)) {
    isActive.value = false;
    return;
  }

  if (props.item.children) {
    isOpen.value = true;
    isActive.value = false;
    return;
  }

  isActive.value = true;
  isOpen.value = false;
});

const toggleOpen = (): void => {
  if (!props.item.children) {
    return;
  }

  isOpen.value = !isOpen.value;
};
</script>

<style lang="scss" scoped>
button {
  @apply hover:bg-gray-200;
}

button.active {
  @apply font-bold;
  box-shadow: inset 4px 0 0px -2px var(--color-secondary);
}

.menu-item.parent > button {
  @apply my-1 px-2;
}

.menu-item.open.parent {
  @apply mb-1;
}

.menu-item.active.closed.parent > button {
  @apply font-bold;
  box-shadow: inset 4px 0 0px -2px var(--color-secondary);
}
</style>
