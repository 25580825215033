<template>
  <div class="lg:w-fit w-0">
    <SideMenuCollapsed v-show="isSidebarCollapsed" />
    <SideMenu v-show="!isSidebarCollapsed" />
  </div>
</template>

<script setup lang="ts">
import SideMenu from './SideMenu.vue';
import SideMenuCollapsed from './SideMenuCollapsed.vue';
import useSidebarToggle from '@/composables/useSidebarToggle';

const {isSidebarCollapsed} = useSidebarToggle();
</script>

<!-- unscoped css to properly style (nested) menu items -->
<style lang="scss">
#main-container-overlay {
  @apply w-full h-full fixed top-0 left-0 z-30 bg-black;
}

.side-menu {
  @apply fixed bg-gray-100 border-r duration-300 h-full top-0 w-72 z-40 pt-1.5;
  @apply lg:z-10 lg:w-56 lg:h-[calc(100vh-70px)] lg:static lg:overflow-y-auto;

  box-shadow: 0 0 6px 4px rgb(0 0 0 / 10%);

  @media (min-width: 998px) {
    box-shadow: inset 0 0 7px -3px rgb(0 0 0 / 30%);
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#navigation--extra--list li {
  @apply leading-7 text-sm;
}
</style>

<style lang="scss">
.internal-menu-item.active {
  @apply font-bold;
}

.menu-children {
  .internal-menu-item,
  .external-menu-item {
    @apply pl-[53px];
  }
}

.menu-item sup {
  @apply text-secondary-100 font-bold;
}

#logout-button:hover {
  color: #982a2f;
}

// Specific styling to add in the impersonation bar
.impersonation {
  .side-menu {
    @apply lg:h-[calc(100vh-94px)];
  }
}
</style>
