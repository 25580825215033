<template>
  <component
    :is="componentType"
    :item="item">
    <span class="menu-item-content">
      <IconBase
        v-if="item.icon"
        class="text-secondary"
        height="24"
        width="24"
        :viewbox="item.icon.viewbox">
        <component
          :is="rawIcon.icon"
          v-if="rawIcon" />
      </IconBase>
      <span
        class="-my-0.5 grow text-left"
        :class="{
          'pl-3': !!item.icon && !isSidebarCollapsed,
          '!pl-3': !item.icon && isSidebarCollapsed,
          'pl-11': !item.icon,
        }">
        <slot />
      </span>
      <IconBase
        v-if="!!item.children && !isSidebarCollapsed"
        class="chevron">
        <ChevronDown />
      </IconBase>
      <IconBase
        v-if="item.redirect"
        class="mr-2 opacity-60 shrink-0">
        <ExternalLink />
      </IconBase>
    </span>
  </component>
</template>

<script setup lang="ts">
import type {MenuItem} from '@/components/ui/layout/SideMenuLegacy/menuItem/menuItem.types';
import {IconBase, ChevronDown, ExternalLink} from '@myparcel-frontend/ui';
import {shallowRef, toRaw, watch} from 'vue';
import ExternalMenuItem from '@/components/ui/layout/SideMenuLegacy/externalMenuItem/ExternalMenuItem.vue';
import InternalMenuItem from '@/components/ui/layout/SideMenuLegacy/internalMenuItem/InternalMenuItem.vue';

const props = defineProps<{
  item: MenuItem;
  isSidebarCollapsed?: boolean;
}>();

const getWrapperComponent = () => {
  if (props.item.children) {
    return 'div';
  }

  if (props.item.redirect) {
    return ExternalMenuItem;
  }

  return InternalMenuItem;
};

const rawIcon = shallowRef();
const componentType = shallowRef();
getWrapperComponent();

watch(
  () => props.item,
  () => {
    rawIcon.value = toRaw(props.item.icon);
  },
  {
    immediate: true,
  },
);

watch(
  () => props.item,
  () => {
    componentType.value = getWrapperComponent();
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss">
.menu-item-content {
  @apply flex items-center p-2 pb-2.5 leading-6 whitespace-break-spaces;
}

.menu-children {
  .menu-item-content {
    @apply p-2;
  }
}

.chevron {
  @apply w-4 h-4 opacity-60;
}

.menu-item.open {
  .chevron {
    @apply transform rotate-180;
  }
}

.menu-children .menu-item-content,
.path {
  // Create a path for the user to move the cursor to from the main menu item to the sub menu items.
  &:before {
    content: '';
    position: absolute;
    cursor: pointer;
    width: 9px;
    height: calc(100% + (9px * 4));
    top: calc((9px * 2) * -1);
    left: -9px;
  }
}
</style>
