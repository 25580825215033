<template>
  <SideMenuExpanded
    class="absolute left-0 lg:flex lg:static top-0 z-40"
    :class="{
      '!hidden lg:!flex': !mobileMenuOpen,
      flex: mobileMenuOpen,
    }"
    :menu="menu"
    @close="() => closeSidebar()">
    <template #parent-menu-item="{item}">
      <ParentMenuItemExpanded
        :item="item"
        :active-route-name="route.name">
        {{ getLabel(item) }}

        <template #external-menu-item="{child}">
          <ExternalMenuItem :item="child">
            {{ getLabel(child) }}
          </ExternalMenuItem>
        </template>

        <template #internal-menu-item="{child}">
          <InternalMenuItem
            :item="child"
            :is-active="child.to.name === route.name || child.activeRouteNameAliases?.includes(route.name)"
            :link-component="RouterLink">
            {{ getLabel(child) }}
          </InternalMenuItem>
        </template>
      </ParentMenuItemExpanded>
    </template>

    <template #external-menu-item="{item}">
      <ExternalMenuItem :item="item">
        {{ getLabel(item) }}
      </ExternalMenuItem>
    </template>

    <template #internal-menu-item="{item}">
      <InternalMenuItem
        :is-active="item.to.name === route.name"
        :item="item"
        :link-component="RouterLink">
        {{ getLabel(item) }}
      </InternalMenuItem>
    </template>

    <template #divider-menu-item>
      <DividerMenuItem />
    </template>

    <ul>
      <DividerMenuItem class="my-4" />
      <InternalMenuItem
        hide-icon
        link-class="!py-1"
        class="!py-0 text-left text-sm"
        :link-component="RouterLink"
        :item="{
          type: 'internal',
          label: 'accountSetting',
          to: {name: 'account-settings-contact'},
        }">
        {{ $t('accountSetting', 2) }}
      </InternalMenuItem>
      <InternalMenuItem
        v-if="hasPermissions(['insertUser', 'deleteUser', 'manageUsers'])"
        hide-icon
        link-class="!py-1"
        class="!py-0 text-left text-sm"
        :link-component="RouterLink"
        :item="{
          type: 'internal',
          label: 'user',
          to: {name: 'users'},
        }">
        {{ $t('user', 2) }}
      </InternalMenuItem>
      <li>
        <Button
          id="logout-button"
          variant="none"
          size="none"
          class="cursor-pointer hover:bg-gray-200 px-4 py-1 text-left text-red-600 text-sm w-full whitespace-nowrap"
          @click="() => logout()">
          {{ $t('logout') }}
        </Button>
      </li>
      <DividerMenuItem class="my-4" />
      <div class="flex justify-between my-3 pl-1 pr-3">
        <CollapseMenuButton
          :aria-label="$t('navigation.collapse')"
          class="hover:!bg-transparent"
          :is-collapsed="isSidebarCollapsed"
          @click="() => collapseSidebar()" />
        <LanguageToggle />
      </div>
    </ul>
  </SideMenuExpanded>
</template>

<script setup lang="ts">
import type {InternalMenuItemConfig} from '@/composables/useMenu/useMenu';
import {
  SideMenuExpanded,
  InternalMenuItem,
  ExternalMenuItem,
  ParentMenuItemExpanded,
  DividerMenuItem,
  CollapseMenuButton,
  Button,
} from '@myparcel-frontend/ui';
import {useI18n} from 'vue-i18n';
import {RouterLink, useRoute} from 'vue-router';
import LanguageToggle from '@/components/ui/LanguageToggle/LanguageToggle.vue';
import useAuth from '@/composables/useAuth';
import useMenu from '@/composables/useMenu';
import useMobileMenu from '@/composables/useMobileMenu';
import useSidebarToggle from '@/composables/useSidebarToggle';

const {getMenu} = useMenu();
const {mobileMenuOpen, closeSidebar} = useMobileMenu();
const {isSidebarCollapsed, collapseSidebar} = useSidebarToggle();
const {logout, hasPermissions} = useAuth();
const {t} = useI18n();

const route = useRoute();
const menu = getMenu();

const getLabel = (item: InternalMenuItemConfig) => {
  return t(item.label, item?.multiplyLabel ? 2 : 1);
};
</script>
