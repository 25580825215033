<template>
  <div
    class="w-0"
    :class="{
      'lg:w-14': isSidebarCollapsed,
      'lg:w-56': !isSidebarCollapsed,
    }">
    <SideMenuCollapsed v-if="isSidebarCollapsed" />
    <SideMenu v-else />
  </div>
</template>

<script setup lang="ts">
import SideMenu from '@/components/ui/layout/sideMenu/SideMenu.vue';
import SideMenuCollapsed from '@/components/ui/layout/sideMenu/SideMenuCollapsed.vue';
import useSidebarToggle from '@/composables/useSidebarToggle';

const {isSidebarCollapsed} = useSidebarToggle();
</script>
